import { useEffect, useRef, useState } from "react";
import {
	Accent,
	Banner,
	BannerCard,
	Button,
	CardList,
	Carosel,
	CaroselItem,
	Col,
	FaIcon,
	H1,
	H2,
	Hero,
	LargeCard,
	MutliTextScroll,
	P,
	PageLayout,
	Ribon,
	Row,
	Section,
	Spacer,
	VideoCard,
	ViewTransition
} from "@sage/shared";
import "./HomePage.scss";

export function HomePage() {
	const [activeCaroselPage, setActiveCaroselPage] = useState("CIMs");
	const intervalRef = useRef(null);

	function updateActiveCaroselPage(page: string) {
		clearInterval(intervalRef.current);
		setActiveCaroselPage(page);
	}

	function loopCarosel() {
		let idx = 0;
		let ids = [
			"CIMs",
			"Teasers",
			"Presentations",
			"Financial Analysis",
			"Charts & Graphs",
			"Information Requests",
			"Data Room Analysis"
		];
		return setInterval(() => {
			if (idx < ids.length - 1) {
				idx++;
			} else {
				idx = 0;
			}
			setActiveCaroselPage(ids[idx]);
		}, 10000);
	}

	useEffect(() => {
		document.title = "Sage AI";
		intervalRef.current = loopCarosel();
		return () => {
			clearInterval(intervalRef.current);
		};
	}, []);

	return (
		<section className="__sage-home-page-container">
			<PageLayout size="wide">
				<Col
					horizontalAlign="center"
					width="100%"
					gap="2rem"
				>
					<Hero>
						Specialized AI for <br />
						<MutliTextScroll
							options={["Investment Banks", "Creating CIMs", "Writing Teasers", "Financial Analysis", "Due Diligence"]}
						/>
						<H2>
							Save <Accent>an hour</Accent> every day at any stage of the deal process. <Accent>Guaranteed.</Accent>
						</H2>
					</Hero>
					<Col
						gap="2.5rem"
						horizontalAlign="center"
						width={"100%"}
					>
						<Button
							variant={"dark"}
							href="/get-started"
						>
							Get Started
						</Button>
						<Ribon>
							<FaIcon
								icon={"file-chart-column"}
								size="2x"
								color={"#a80300"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "CIMs"}
								onClick={() => updateActiveCaroselPage("CIMs")}
							>
								CIMs/CIPs
							</FaIcon>
							<FaIcon
								icon={"memo"}
								size="2x"
								color={"#30abc6"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "Teasers"}
								onClick={() => updateActiveCaroselPage("Teasers")}
							>
								Teasers
							</FaIcon>
							<FaIcon
								icon={"screen-users"}
								size="2x"
								color={"#f7a501"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "Presentations"}
								onClick={() => updateActiveCaroselPage("Presentations")}
							>
								Presentations
							</FaIcon>
							<FaIcon
								icon={"file-spreadsheet"}
								size="2x"
								color={"#00a857"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "Financial Analysis"}
								onClick={() => updateActiveCaroselPage("Financial Analysis")}
							>
								Financial Analysis
							</FaIcon>
							<FaIcon
								icon={"chart-pie-simple-circle-dollar"}
								size="2x"
								color={"#5700a8"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "Charts & Graphs"}
								onClick={() => updateActiveCaroselPage("Charts & Graphs")}
							>
								Charts & Graphs
							</FaIcon>
							<FaIcon
								icon={"ballot-check"}
								size="2x"
								color={"#0051a8"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "Information Requests"}
								onClick={() => updateActiveCaroselPage("Information Requests")}
							>
								Information Requests
							</FaIcon>
							<FaIcon
								icon={"folder-tree"}
								size="2x"
								color={"#00a857"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "Data Room Analysis"}
								onClick={() => updateActiveCaroselPage("Data Room Analysis")}
							>
								Data Room Analysis
							</FaIcon>
						</Ribon>
						<Carosel activePage={activeCaroselPage}>
							<CaroselItem
								id="CIMs"
								backgroundColor={"#e3adac"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/home-page-cim-ts-3.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<Col gap={"0.5rem"}>
												<H1>Create CIM/CIP Drafts In Minutes </H1>
												<P>Create a cim in 30 minutes or less</P>
											</Col>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"person-running-fast"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>
													Build CIMs and CIPs drafts in minutes using an intuitive AI user interface connected
													directly to the data room.
												</P>
											</Row>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"chart-waterfall"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>
													Pick your slide elements and let AI fill them with data room information and charts that
													fit your firm’s exact output.
												</P>
											</Row>
											<Row horizontalAlign="right">
												<ViewTransition>
													<Button
														href="/get-started"
														variant="dark"
													>
														Get Started
													</Button>
												</ViewTransition>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Teasers"
								backgroundColor={"#c0e5ee"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/home-page-teaser.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Create Comprehensive Teasers</H1>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"business-time"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>
													Create anonymized teasers in minutes by pulling data straight from your data room and
													past deals.
												</P>
											</Row>
											<Row horizontalAlign="right">
												<ViewTransition>
													<Button
														href="/get-started"
														variant="dark"
													>
														Get Started
													</Button>
												</ViewTransition>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Presentations"
								backgroundColor={"#fde4b2"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/home-page-presentations.avif"} />
										</ViewTransition>
									</div>

									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Create Management Presentation Drafts Fast</H1>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"print"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>Create compelling management decks in minutes using your templates.</P>
											</Row>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"chart-pie"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>Add charts and analysis directly from your data room to tell your client's story.</P>
											</Row>
											<Row horizontalAlign="right">
												<ViewTransition>
													<Button
														href="/get-started"
														variant="dark"
													>
														Get Started
													</Button>
												</ViewTransition>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Financial Analysis"
								backgroundColor={"#ace3c8"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/home-page-financial-analysis.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Perform Complex Financial Analysis</H1>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"layer-group"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>Turn messy financial data into clear insights and charts in seconds.</P>
											</Row>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"table-layout"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>Get instant analysis for CIMs, models, and management decks without touching Excel.</P>
											</Row>
											<Row horizontalAlign="right">
												<ViewTransition>
													<Button
														href="/get-started"
														variant="dark"
													>
														Get Started
													</Button>
												</ViewTransition>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Charts & Graphs"
								backgroundColor={"#c8ace3"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/home-page-charts-graphs.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Create Complex Charts & Graphs</H1>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"chart-line-up"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>
													Get instant answers to buyer questions with charts and data straight from your data
													room.
												</P>
											</Row>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"chart-network"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>
													Just ask in plain English - 'Show me revenue by customer' - and Sage creates the perfect
													visualization.
												</P>
											</Row>
											<Row horizontalAlign="right">
												<ViewTransition>
													<Button
														href="/get-started"
														variant="dark"
													>
														Get Started
													</Button>
												</ViewTransition>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Information Requests"
								backgroundColor={"#acc6e3"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/home-page-info-request.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Build precise information requests in minutes.</H1>
											<P>Build your info request list in minutes, not hours. </P>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"list-check"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>
													Sage removes irrelevant items and adds important deal-specific requests automatically.
												</P>
											</Row>
											<Row horizontalAlign="right">
												<ViewTransition>
													<Button
														href="/get-started"
														variant="dark"
													>
														Get Started
													</Button>
												</ViewTransition>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Data Room Analysis"
								backgroundColor={"#ace3c8"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/home-page-data-room-ts.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Find Any Information in the Data Room In Seconds</H1>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"person-running-fast"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>Find what you need instantly in any data room - even if you have thousands of files. </P>
											</Row>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"chart-waterfall"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
													/>
												</ViewTransition>
												<P>You can even create things like charts, graphs, and tables.</P>
											</Row>
											<Row horizontalAlign="right">
												<ViewTransition>
													<Button
														href="/get-started"
														variant="dark"
													>
														Get Started
													</Button>
												</ViewTransition>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
						</Carosel>
					</Col>
				</Col>
			</PageLayout>
			<PageLayout>
				<Section name="ai-powered-solutions">
					<LargeCard
						title={
							<>
								From Signup to CIM Slides in <Accent>30 Minutes</Accent> or Less
							</>
						}
						content={
							"Just drop in your files and start getting answers in seconds.\n\nWhether it’s 20 files or 2000 files, Sage will find what you’re looking for in seconds.\n\nOur intuitive interface lets you hit the ground running. Pull key information, draft complex slides, and answer diligence questions."
						}
						card={
							<VideoCard
								thumbnail={"https://sageai.dev/cdn/website/assets/home-page-thumbnail.avif"}
								video={"https://sageai.dev/cdn/website/assets/workflows.mp4"}
							/>
						}
					/>
				</Section>
			</PageLayout>
			<Section name="next-steps">
				<Spacer>
					<Banner
						src={"https://cdn.sageai.dev/website/assets/mid-roll-banner-dark.avif"}
						size={"sm"}
						center={
							<BannerCard
								variant={"center"}
								color="_dark"
								title={"Sounds too good to be true?"}
								content={
									"That’s why we don’t expect you to believe us.\n\nMeet with a member of our team to see if Sage will work for your team in 10 minutes.\n\nOur teams work investment banking hours just like yours, so we’re available to meet with team members late at night and on weekends. Whenever works for you, works for us."
								}
								actions={<Button href={"/get-started"}>Get Started</Button>}
							/>
						}
					/>
				</Spacer>
				<Spacer>
					<CardList
						cards={[
							{
								title: "Schedule a Demo",
								image: "https://cdn.sageai.dev/website/assets/docs-athena-close-up-1.avif",
								content: "Developed from M&A processes at industry leading firms to address real-world challenges.",
								href: "/get-started",
								cta: "Schedule"
							},
							{
								title: "Sage AI Data Security",
								content:
									"At Sage AI, data security is ensured through our three layer approach. Document Level Security, Account Level Security and System Level Security.\n\nEach tier of security & controls reinforces the others. To see exactly how this works, take a look at this page.",
								href: "/security/how-we-secure-your-data",
								image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif"
							}
						]}
					/>
				</Spacer>
			</Section>
		</section>
	);
}
